/* import this in other css to use ant design */

@import '~antd/dist/antd.css';
/* pc */

#homepage {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin: 0px 50px;
    align-content: center;
}

/*  need alternative solution for mobile use
    multiple buttons in row won't look good 
*/

.poster {
    position: relative;
    width: 70%;
    height: 70%;
    box-shadow: 0px 0px 10px #ffffff;
}

.homepage-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.a {
    font-size: 2rem;
    font-family: 'Montserrat Bold';
    color: #000000;
}
